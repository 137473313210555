<template>
  <div class="newTask">
    <!-- top -->
      <el-breadcrumb separator="/">
          <el-breadcrumb-item>受检管理</el-breadcrumb-item>
          <el-breadcrumb-item :to="{path:'/checkTask'}">检测任务</el-breadcrumb-item>
          <el-breadcrumb-item>创建任务</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="center">
           <span class="blueTitle" style="margin-left:12px;">创建任务</span>
          <div class="create">
              <div class="title">
                <span class="left">任务信息</span>
                <span class="right">
                  <!-- <span class="cancel">取消</span> -->
                   <el-popconfirm title="确认新建任务吗" @confirm="isCreate('formTask')" @cancel="noCreate">
                      <span class="save" slot="reference">保存</span>
                    </el-popconfirm>
                </span>
              </div>
              <div class="selectBox">
                    <el-form :inline="true" :rules="rules" :model="formTask" ref="formTask" class="demo-form-inline">
                        <el-form-item label="任务名称" style="margin-left:24px;" prop="taskName">
                          <el-input v-model="formTask.taskName" placeholder="输入筛查任务名"></el-input>
                        </el-form-item>
                        <el-form-item label="计划检测数量" style="margin-left:24px;" prop="planExamineQty">
                          <el-input v-model.number="formTask.planExamineQty" placeholder="输入计划检测数量" oninput ="value=value.replace(/[^0-9]/g,'')"></el-input>
                        </el-form-item>
                        <el-form-item label="检测机构" style="margin-left:44px;" prop="orgId">
                          <el-select v-model="formTask.orgId" placeholder="请选择机构"
                            clearable filterable
                            v-scroll="handleScroll"
                            size="medium"
                            @focus="slectOrgName">
                              <el-option
                              v-for="(item,index) in orgList"
                              :key="index"
                              :label="item.orgName"
                              :value="item.id">
                            </el-option>
                          </el-select>
                        </el-form-item>
                    </el-form>
              </div>
          </div>
      </div>
  </div>
</template>
<script>
import {createCheckTask} from '@/api/checkTask.js'
import {getOrgList} from '@/api/orgController.js'

export default {
    data(){
      return{
        testid:0,
        formTask:{
          taskName:'',
          planExamineQty:"",//计划检测数量
          orgId:'',
        },
        //下拉加载更多
            pageSize: 1000,
            pageNum: 1,
            total: 0,
      
        totalPage: '',
        orgList:[],
        // 下拉加载更多结束

        //校验
        rules:{
          taskName:[{ required: true, message: '请填写任务名称', trigger: 'blur' }],
          planExamineQty:[{ type: 'number', required: true, message: '请输入计划检测数量', trigger: 'blur' }],
          orgId:[{ required: true, message: '请选择检测机构', trigger: 'blur' }],

        }
      }
    },
    watch:{},
    methods:{
      // 滚动加载更多
      handleScroll(){
       // console.log("下滑了",parseInt(this.testid));
     
         
        },
      // 获取检测机构名称
      slectOrgName() {
          getOrgList({
            "currentPage":this.pageNum,
            "pageSize":this.pageSize,
          }).then(res=>{
            if(res.data.status == 200){
              const orgListF =res.data.data.records;
              const newOrgList =[];
              orgListF.forEach((curr,index)=>{
                  if(curr.orgType == "检测机构"){
                      newOrgList.push(curr);
                  }
              });
              // this.orgList = this.orgList.concat(orgListF);// 是否需要并接，还未知
              this.orgList = newOrgList;
              this.totalPage = res.data.data.pages;
              this.total =res.data.data.total;

            }else{
              this.$message.error(res.data.message);
            }
          }).catch(res=>{
             this.$message.error(res);
          })
      },
      //确认创建任务
      isCreate(formName){
          this.$refs[formName].validate((valid) => {
          if (valid) {
              const that =this;
              createCheckTask({
                taskName:that.formTask.taskName,
                orgId:that.formTask.orgId,
                planExamineQty:that.formTask.planExamineQty
              }).then(res=>{
                if(res.data.status == 200){
                  this.$message({
                  message: '创建任务成功！',
                  type: 'success'
                });
                const taskId =  res.data.data.id;
                //clear
                setTimeout(()=>{
                    this.formTask.taskName="";
                    this.formTask.planExamineQty='';
                    this.formTask.orgId = "";
                    //to task detail
                    this.$router.push({
                      path:'/checkTask/taskDetails',
                      query:{
                        id:taskId
                      }
                    })
                },1500)
                }
                  else{
                    this.$message.error(res.data.message);
                }
              }).catch(res=>{
                 this.$message.error(res);
              })
          } else {
            this.$message.error("请正确填写相应内容！");
            return false;
          }
        });

      },
      //取消提交任务
      noCreate(){
          //console.log("取消了！");
          this.formTask.taskName="",
          this.formTask.planExamineQty='',
          this.formTask.orgId = ""
      },




  }
}
</script>

<style lang="stylus">
//change elemen-ui style
@import '~@/assets/css/newTask.css'
//comstyle
.blueTitle
  font-size: 16px
  color: #353535
  line-height: 28px
  font-weight: bold
  position: relative
  &::before
    width: 3px
    height: 18px
    content: ""
    position: absolute
    border-radius: .03rem
    left: -10px
    bottom:1px
    background-color: #00CCCC
.center
  width: 100%
  overflow hidden
  margin-top: 24px
  .download
    width: 116px
    height: 32px
    background-color: #00CCCC
    font-size:14px
    font-family: Source Han Sans CN
    line-height: 32px
    text-align: center
    color: #fff
    border-radius: 2px
    cursor pointer
  .create
    width: 100%
    margin-top: 24px
    .title
      width: 100%
      height: 32px
      background-color: #00cccc
      display: flex
      justify-content: space-between
      align-items: center
      .left
        margin-left: 20px
        font-family: Source Han Sans CN
        font-size: 16px
        color: #fff
      .right
        margin-right: 20px
        .cancel
          margin-right: 16px
          font-family: Source Han Sans CN
          font-size: 16px
          color: #fff
          cursor pointer
        .save
          font-family: Source Han Sans CN
          font-size: 16px
          cursor pointer
          color: #fff
    .selectBox
      width: 100%
      height: 200px
      padding: 24px 0
      background-color: #fff
      .demo-form-inline
        margin-top: 60px
</style>